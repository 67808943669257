import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#FFFFFF',
      light: '#917FB1',
      dark: '#ffffff',
    },
    secondary: {
      main: '#212121',
      light: '#7f7f7f',
      dark: '#90AE0D',
    },
    error: {
      main: '#FF0033',
      light: '#FF4C70',
      dark: '#B20023',
    },
    warning: {
      main: '#EED202',
      light: '#F3DF4D',
      dark: '#A69301',
    },
    success: {
      main: '#4BB543',
      light: '#82CC7D',
      dark: '#34942F',
    },
    grey: {
      main: '#293845',
      light: '#69737C',
      dark: '#1C2730',
    },
    reg: {
      main: '#28a745',
      light: '#7eca90',
      dark: '#1c7531',
    },
    del: {
      main: '#dc3545',
      light: '#ea868f',
      dark: '#9a2530',
    },
    color1: {
      main: '#F724BE',
      light: '#69737C',
      dark: '#1C2730',
    },
    color2: {
      main: '#AD24F7',
      light: '#69737C',
      dark: '#1C2730',
    },
    color3: {
      main: '#24A7F7',
      light: '#69737C',
      dark: '#1C2730',
    },
    color4: {
      main: '#F78124',
      light: '#69737C',
      dark: '#1C2730',
    },
    color5: {
      main: '#00FF00',
      light: '#69737C',
      dark: '#1C2730',
    },
    color6: {
      main: '#5B9605',
      light: '#69737C',
      dark: '#1C2730',
    },
    color7: {
      main: '#0EBFBE',
      light: '#69737C',
      dark: '#1C2730',
    },
    color8: {
      main: '#405714',
      light: '#8c9a72',
      dark: '#2d3d0e',
    },
    color9: {
      main: '#1975ff',
      light: '#66a3ff',
      dark: '#0047b3',
    },
    color10: {
      main: '#ffc400',
      light: '#ffdc66',
      dark: '#b38900',
    },
    color11: {
      main: '#3e31a3',
      light: '#8b83c8',
      dark: '#2b2272',
    },
    color12: {
      main: '#115204',
      light: '#709768',
      dark: '#0c3903',
    },
    filter1: {
      main: '#420420',
      light: '#69737C',
      dark: '#1C2730',
    },
    filter2: {
      main: '#250A8F',
      light: '#69737C',
      dark: '#1C2730',
    },
    filter3: {
      main: '#068033',
      light: '#69737C',
      dark: '#1C2730',
    },
    filter4: {
      main: '#8F6A0A',
      light: '#69737C',
      dark: '#1C2730',
    },
    filter5: {
      main: '#B1A514',
      light: '#69737C',
      dark: '#1C2730',
    },
    filter6: {
      main: '#740747',
      light: '#69737C',
      dark: '#1C2730',
    },
    filter7: {
      main: '#8F0A54',
      light: '#69737C',
      dark: '#1C2730',
    },
    filter8: {
      main: '#387906',
      light: '#69737C',
      dark: '#1C2730',
    },
    filter9: {
      main: '#5280e3',
      light: '#97b3ee',
      dark: '#395a9f',
    },
    filter10: {
      main: '#038f7a',
      light: '#68bcaf',
      dark: '#026455',
    },
    filter11: {
      main: '#ad2188',
      light: '#ce7ab8',
      dark: '#79175f',
    },
    difRise: {
      main: '#3FBF0E',
      light: '#69737C',
      dark: '#1C2730',
    },
    difFall: {
      main: '#BF130E',
      light: '#69737C',
      dark: '#1C2730',
    },
  },
  // typography: {
  //   fontFamily: "Roboto"
  // }
});

export default theme;
