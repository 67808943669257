import React from "react";
import AppSideBar from "../components/navigation/AppSideBar";

const Layout = ({ children }) => {
  return (
    <>
      <AppSideBar id="app-side-bar">{children}</AppSideBar>
    </>
  );
};

export default Layout;
