import * as Sentry from '@sentry/node';
import axios from 'axios';

export const checkUser = async (
  keycloakId,
  email,
  lastName,
  firstName,
  gemiNumber
) => {
  let userInfos;
  try {
    let token = sessionStorage.getItem('authentication');
    let headers = {
      Authorization: 'bearer ' + token,
    };
    userInfos = await axios.post(
      process.env.NEXT_PUBLIC_BE_KEY_VARIABLE.concat(`requests/checkUser/`),
      {},
      {
        params: { keycloakId, email, lastName, firstName, gemiNumber },
        headers,
      }
    );
    return await userInfos.data;
  } catch (error) {
    console.error(error);
    Sentry.captureException(error);
    return error.response.status;
  }
};
