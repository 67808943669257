import { ThemeProvider } from "@material-ui/core/styles";
import * as Sentry from "@sentry/node";
import { useMemo, useState } from "react";
import Snackbar from "../components/assets/Snackbar";
import Layout from "../layout/Layout";
import { AuthProvider, ProtectRoute } from "../src/AuthKeycloakProvider";
import { ErrorHandling } from "../src/context/ErrorContext";
import theme from "../src/theme/theme";
import UserContext from "../src/UserContext";
import "../styles/globals.css";

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: process.env.NODE_ENV === "production",
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  });
}

function MyApp({ Component, pageProps, err }) {
  const [openModal, setOpenModal] = useState(false);

  const handleClick = () => {
    setOpenModal(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenModal(false);
  };

  const [keycloakstate, setKeycloakstate] = useState({
    keycloak: null,
    authenticated: false,
    token: null,
    userName: null,
    user_id: null,
    roles: null,
  });

  const value = useMemo(
    () => ({ keycloakstate, setKeycloakstate }),
    [keycloakstate, setKeycloakstate]
  );

  return (
    <UserContext.Provider value={value}>
      <AuthProvider>
        <ProtectRoute>
          <ThemeProvider theme={theme}>
            <ErrorHandling.Provider
              value={{ openModal, setOpenModal, handleClose, handleClick }}
            >
              <Layout>
                <Snackbar />
                  <Component {...pageProps} err={err} />
              </Layout>
            </ErrorHandling.Provider>
          </ThemeProvider>
        </ProtectRoute>
      </AuthProvider>
    </UserContext.Provider>
  );
}

export default MyApp;
