export function getUserType(keycloakState) {
  if (
    keycloakState?.authenticated === true &&
    JSON.parse(sessionStorage.getItem("userSession"))?.isAdmin
  ) {
    return "admin";
  } else if (
    keycloakState?.authenticated === true &&
    JSON.parse(sessionStorage.getItem("userSession"))?.isChamberUser
  ) {
    return "chamberUser";
  }
}

export function getUserPermissions(feature) {
    if (sessionStorage.getItem("roles")) {
      let admin = JSON.parse(sessionStorage.getItem("roles")).filter(
        (x) => x == process.env.admin
      );
      let featureRole = JSON.parse(sessionStorage.getItem("roles")).filter(
        (x) => x == feature
      );
      if (admin.length > 0) {
        return "admin";
      } else if (featureRole.length > 0) {
        return "featureRole";
      }
    } else {
      return;
    }
  }