import Collapse from '@material-ui/core/Collapse';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import BarChartIcon from '@material-ui/icons/BarChart';
import BusinessIcon from '@material-ui/icons/Business';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import EuroIcon from '@material-ui/icons/Euro';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import HomeIcon from '@material-ui/icons/Home';
import RoomIcon from '@material-ui/icons/Room';
import ScatterPlotIcon from '@material-ui/icons/ScatterPlot';
import TimelineIcon from '@material-ui/icons/Timeline';
import WorkIcon from '@material-ui/icons/Work';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useContext, useState } from 'react';
import {
  getUserPermissions,
  getUserType,
} from '../../public/authentication/authentication';
import UserContext from '../../src/UserContext';

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  nestedClose: {
    paddingLeft: 0,
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  listItemSelected: {
    fontWeight: 450,
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  listItem: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
}));

const SideBar = (props) => {
  const [open, setOpen] = useState(true);
  const [openExpenses, setOpenExpenses] = useState(true);
  const [openRegistrations, setOpenRegistrations] = useState(true);
  const router = useRouter();
  const classes = useStyles();
  const { openSideBar } = props;

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClickExpenses = () => {
    setOpenExpenses(!openExpenses);
  };

  const handleClickRegistrations = () => {
    setOpenRegistrations(!openRegistrations);
  };

  let { keycloakstate } = useContext(UserContext);

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      <div
        style={{
          overflowY: 'scroll',
          overflowX: 'hidden',
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: -17,
        }}
      >
        <List style={{ paddingTop: 0, paddingBottom: 0 }}>
          <Link href='/dashboard'>
            <a style={{ color: 'inherit', textDecoration: 'none' }}>
              <ListItem button key='Αρχική' className={classes.listItem}>
                <ListItemIcon>
                  <HomeIcon
                    fontSize='small'
                    style={
                      router.pathname.includes('dashboard') ||
                      router.pathname === '/'
                        ? { color: '#000000' }
                        : null
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  primary='Αρχική'
                  classes={{
                    primary:
                      router.pathname.includes('dashboard') ||
                      router.pathname === '/'
                        ? classes.listItemSelected
                        : null,
                  }}
                />
              </ListItem>
            </a>
          </Link>
        </List>
        <Divider />
        {(getUserType(keycloakstate) == 'admin' &&
          getUserPermissions(process.env.inc_exp) == 'admin') ||
        (getUserType(keycloakstate) == 'chamberUser' &&
          getUserPermissions(process.env.inc_exp) == 'featureRole') ? (
          <>
            <ListItem button onClick={handleClick}>
              <ListItemIcon>
                <EuroIcon fontSize='small' />
              </ListItemIcon>
              <ListItemText primary='Έσοδα' />
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Divider />
            <Collapse in={open} timeout='auto' unmountOnExit>
              <List style={{ paddingTop: 0, paddingBottom: 0 }}>
                <Link href='/allincome'>
                  <a style={{ color: 'inherit', textDecoration: 'none' }}>
                    <ListItem
                      button
                      key='Επιμελητηρίου (στο χρόνο)'
                      id='all'
                      className={
                        openSideBar ? classes.nested : classes.nestedClosed
                      }
                    >
                      <ListItemIcon>
                        <TimelineIcon
                          style={
                            router.pathname.includes('allincome')
                              ? { color: '#000000' }
                              : null
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary='Επιμελητηρίου (στο χρόνο)'
                        classes={{
                          primary: router.pathname.includes('allincome')
                            ? classes.listItemSelected
                            : null,
                        }}
                      />
                    </ListItem>
                  </a>
                </Link>
              </List>
              <Divider />
              <List style={{ paddingTop: 0, paddingBottom: 0 }}>
                <Link href='/incomebytype'>
                  <a style={{ color: 'inherit', textDecoration: 'none' }}>
                    <ListItem
                      button
                      key='Επιμελητηρίου (ομαδοποιημένα)'
                      className={
                        openSideBar ? classes.nested : classes.nestedClosed
                      }
                    >
                      <ListItemIcon>
                        <BarChartIcon
                          style={
                            router.pathname.includes('incomebytype')
                              ? { color: '#000000' }
                              : null
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary='Επιμελητηρίου (ομαδοποιημένα)'
                        classes={{
                          primary: router.pathname.includes('incomebytype')
                            ? classes.listItemSelected
                            : null,
                        }}
                      />
                    </ListItem>
                  </a>
                </Link>
              </List>
              <Divider />
              <List style={{ paddingTop: 0, paddingBottom: 0 }}>
                <Link href='/gemiincome'>
                  <a style={{ color: 'inherit', textDecoration: 'none' }}>
                    <ListItem
                      button
                      key='Εισπράξεις Επιμελητηρίου'
                      className={
                        openSideBar ? classes.nested : classes.nestedClosed
                      }
                    >
                      <ListItemIcon>
                        <BusinessIcon
                          style={
                            router.pathname.includes('gemiincome')
                              ? { color: '#000000' }
                              : null
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary='Εισπράξεις Γ.Ε.Μη'
                        classes={{
                          primary: router.pathname.includes('gemiincome')
                            ? classes.listItemSelected
                            : null,
                        }}
                      />
                    </ListItem>
                  </a>
                </Link>
              </List>
              <Divider />
            </Collapse>
            <ListItem button onClick={handleClickExpenses}>
              <ListItemIcon>
                <EuroIcon fontSize='small' />
              </ListItemIcon>
              <ListItemText primary='Έξοδα' />
              {openExpenses ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Divider />
            <Collapse in={openExpenses} timeout='auto' unmountOnExit>
              <List style={{ paddingTop: 0, paddingBottom: 0 }}>
                <Link href='/allexpenses'>
                  <a style={{ color: 'inherit', textDecoration: 'none' }}>
                    <ListItem
                      button
                      key='Επιμελητηρίου (στο χρόνο)'
                      id='all'
                      className={
                        openSideBar ? classes.nested : classes.nestedClosed
                      }
                    >
                      <ListItemIcon>
                        <TimelineIcon
                          style={
                            router.pathname.includes('allexpenses')
                              ? { color: '#000000' }
                              : null
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary='Επιμελητηρίου (στο χρόνο)'
                        classes={{
                          primary: router.pathname.includes('allexpenses')
                            ? classes.listItemSelected
                            : null,
                        }}
                      />
                    </ListItem>
                  </a>
                </Link>
              </List>
              <Divider />
              <List style={{ paddingTop: 0, paddingBottom: 0 }}>
                <Link href='/expensesbytype'>
                  <a style={{ color: 'inherit', textDecoration: 'none' }}>
                    <ListItem
                      button
                      key='Επιμελητηρίου (ομαδοποιημένα)'
                      className={
                        openSideBar ? classes.nested : classes.nestedClosed
                      }
                    >
                      <ListItemIcon>
                        <BarChartIcon
                          style={
                            router.pathname.includes('expensesbytype')
                              ? { color: '#000000' }
                              : null
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary='Επιμελητηρίου (ομαδοποιημένα)'
                        classes={{
                          primary: router.pathname.includes('expensesbytype')
                            ? classes.listItemSelected
                            : null,
                        }}
                      />
                    </ListItem>
                  </a>
                </Link>
              </List>
              <Divider />
            </Collapse>
          </>
        ) : null}
        <ListItem button onClick={handleClickRegistrations}>
          <ListItemIcon>
            <CompareArrowsIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText primary='Εγγραφές/Διαγραφές' />
          {openRegistrations ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Divider />
        <Collapse in={openRegistrations} timeout='auto' unmountOnExit>
          {(getUserType(keycloakstate) == 'admin' &&
            getUserPermissions(process.env.establishments) == 'admin') ||
          (getUserType(keycloakstate) == 'chamberUser' &&
            getUserPermissions(process.env.establishments) == 'featureRole') ? (
            <>
              <List style={{ paddingTop: 0, paddingBottom: 0 }}>
                <Link href='/establishments'>
                  <a style={{ color: 'inherit', textDecoration: 'none' }}>
                    <ListItem
                      button
                      key='Στο χρόνο'
                      className={
                        openSideBar ? classes.nested : classes.nestedClosed
                      }
                    >
                      <ListItemIcon>
                        <CompareArrowsIcon
                          style={
                            router.pathname.includes('establishments')
                              ? { color: '#000000' }
                              : null
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary='Στο χρόνο'
                        classes={{
                          primary: router.pathname.includes('establishments')
                            ? classes.listItemSelected
                            : null,
                        }}
                      />
                    </ListItem>
                  </a>
                </Link>
              </List>
              <Divider />
              <List style={{ paddingTop: 0, paddingBottom: 0 }}>
                <Link href='/regdelgrouped'>
                  <a style={{ color: 'inherit', textDecoration: 'none' }}>
                    <ListItem
                      button
                      key='Ανά Νομική Μορφή'
                      className={
                        openSideBar ? classes.nested : classes.nestedClosed
                      }
                    >
                      <ListItemIcon>
                        <BarChartIcon
                          style={
                            router.pathname.includes('regdelgrouped')
                              ? { color: '#000000' }
                              : null
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary='Ανά Νομική Μορφή'
                        classes={{
                          primary: router.pathname.includes('regdelgrouped')
                            ? classes.listItemSelected
                            : null,
                        }}
                      />
                    </ListItem>
                  </a>
                </Link>
              </List>
              <Divider />
            </>
          ) : null}
        </Collapse>
        {(getUserType(keycloakstate) == 'admin' &&
          getUserPermissions(process.env.dem_geo) == 'admin') ||
        (getUserType(keycloakstate) == 'chamberUser' &&
          getUserPermissions(process.env.dem_geo) == 'featureRole') ? (
          <>
            <List style={{ paddingTop: 0, paddingBottom: 0 }}>
              <Link href='/demography'>
                <a style={{ color: 'inherit', textDecoration: 'none' }}>
                  <ListItem
                    button
                    key='Δημογραφία'
                    className={classes.listItem}
                  >
                    <ListItemIcon>
                      <ScatterPlotIcon
                        style={
                          router.pathname.includes('demography')
                            ? { color: '#000000' }
                            : null
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary='Δημογραφία'
                      classes={{
                        primary: router.pathname.includes('demography')
                          ? classes.listItemSelected
                          : null,
                      }}
                    />
                  </ListItem>
                </a>
              </Link>
            </List>
            <Divider />
          </>
        ) : null}
        {(getUserType(keycloakstate) == 'admin' &&
          getUserPermissions(process.env.gemicases) == 'admin') ||
        (getUserType(keycloakstate) == 'chamberUser' &&
          getUserPermissions(process.env.gemicases) == 'featureRole') ? (
          process.env.gemiCasesEnabled ? (
            <>
              <List style={{ paddingTop: 0, paddingBottom: 0 }}>
                <Link href='/gemicases'>
                  <a style={{ color: 'inherit', textDecoration: 'none' }}>
                    <ListItem
                      button
                      key='Υποθέσεις Γ.Ε.Μη'
                      className={classes.listItem}
                    >
                      <ListItemIcon>
                        <WorkIcon
                          style={
                            router.pathname.includes('gemicases')
                              ? { color: '#000000' }
                              : null
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary='Υποθέσεις Γ.Ε.Μη'
                        classes={{
                          primary: router.pathname.includes('gemicases')
                            ? classes.listItemSelected
                            : null,
                        }}
                      />
                    </ListItem>
                  </a>
                </Link>
              </List>
              <Divider />
            </>
          ) : (
            <></>
          )
        ) : null}
        {(getUserType(keycloakstate) == 'admin' &&
          getUserPermissions(process.env.dem_geo) == 'admin') ||
        (getUserType(keycloakstate) == 'chamberUser' &&
          getUserPermissions(process.env.dem_geo) == 'featureRole') ? (
          <>
            <List style={{ paddingTop: 0, paddingBottom: 0 }}>
              <Link href='/geography'>
                <a style={{ color: 'inherit', textDecoration: 'none' }}>
                  <ListItem
                    button
                    key='Γεωγραφική απεικόνιση'
                    className={classes.listItem}
                  >
                    <ListItemIcon>
                      <RoomIcon
                        style={
                          router.pathname.includes('geography')
                            ? { color: '#000000' }
                            : null
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary='Γεωγραφική απεικόνιση'
                      classes={{
                        primary: router.pathname.includes('geography')
                          ? classes.listItemSelected
                          : null,
                      }}
                    />
                  </ListItem>
                </a>
              </Link>
            </List>
            <Divider />
          </>
        ) : null}
      </div>
    </div>
  );
};
export default SideBar;
